import { ref } from "vue";
import { useRouter } from "vue-router";
import { pageCustomer } from "@/api/appNotice"; // 引入封装的接口

import { hideLoading, showLoading } from "@/utils/utils";
export default {
  name: "server",

  setup() {
    const router = useRouter();
    const loading = ref(false); // 控制加载状态

    const finished = ref(false); // 控制是否已加载完所有数据

    const items = ref([]); // 存储客服列表

    const currentPage = ref(1); // 当前页码

    const totalItems = ref(0); // 总条目数

    const pageSize = 10; // 每次加载的条目数

    const telegramIcon = "https://web.afrontendhhkk.com/icon/telegram.png";
    const whatsappIcon = "https://web.afrontendhhkk.com/icon/whatsapp.png"; // 返回上一页

    const onClickLeft = () => router.back(); // 加载更多数据


    const onLoad = async () => {
      showLoading("Loading..."); // 显示加载状态

      loading.value = true;

      try {
        const response = await pageCustomer({
          current: currentPage.value,
          size: pageSize
        });

        if (response.code === 1) {
          const newItems = response.data.records;
          totalItems.value = response.data.total;
          items.value.push(...newItems); // 添加新加载的数据
          // 如果加载的总条目数已达到总数据条目数，则标记为已加载完成

          if (items.value.length >= totalItems.value) {
            finished.value = true;
          } else {
            currentPage.value++;
          }
        } else {
          console.error("获取客服数据失败", response.msg);
        }
      } catch (error) {
        console.error("接口请求失败", error);
      }

      hideLoading(); // 隐藏加载状态

      loading.value = false; // 停止加载状态
    }; // 跳转到客服链接


    const connectToService = serverLink => {
      window.open(serverLink, "_blank");
    };

    return {
      onClickLeft,
      onLoad,
      items,
      loading,
      finished,
      telegramIcon,
      whatsappIcon,
      connectToService
    };
  }

};