import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-98f880a6"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  style: {
    "padding": "0.6rem 2rem 1rem"
  }
};
const _hoisted_2 = {
  class: "server_list"
};
const _hoisted_3 = {
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  style: {
    "padding-left": "0.5rem"
  }
};
const _hoisted_6 = {
  style: {
    "font-size": "1rem",
    "font-weight": "600"
  }
};
const _hoisted_7 = {
  style: {
    "font-size": "0.8rem",
    "color": "rgb(153, 153, 153)",
    "padding": "0.2rem 0rem"
  }
};
const _hoisted_8 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");

  const _component_van_list = _resolveComponent("van-list");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_nav_bar, {
    title: "Customer Service",
    "left-arrow": "",
    onClickLeft: $setup.onClickLeft
  }, null, 8, ["onClickLeft"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_list, {
    loading: $setup.loading,
    "onUpdate:loading": _cache[0] || (_cache[0] = $event => $setup.loading = $event),
    finished: $setup.finished,
    "finished-text": "没有更多了",
    onLoad: $setup.onLoad
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, item => {
      return _openBlock(), _createElementBlock("div", {
        key: item.id
      }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, [_createElementVNode("img", {
        src: item.type == '0' ? $setup.telegramIcon : $setup.whatsappIcon,
        alt: "客服类型",
        style: {
          "width": "3rem",
          "height": "3rem"
        }
      }, null, 8, _hoisted_4)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(item.onlineStatus == "1" ? "在线" : "离线"), 1)])]), _createElementVNode("div", {
        class: "btn",
        onClick: $event => $setup.connectToService(item.serverLink)
      }, " Connect ", 8, _hoisted_8)])]);
    }), 128))]),
    _: 1
  }, 8, ["loading", "finished", "onLoad"])])]);
}